import React, { useEffect, useRef, useState } from "react";
import { useLoader } from "../../context/Loader/LoaderProvider";
import Loader from "../../context/Loader/Loader";
import { showErrorToast } from "../../utils/Toaster";
import jsPDF from "jspdf";
import "jspdf-autotable"; // Import autoTable plugin
import { TabView, TabPanel } from "primereact/tabview";
import { Calendar } from "primereact/calendar"; // Import Calendar component
import * as XLSX from "xlsx";
import axiosPrivate from "../../hooks/axiosPrivate";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FaRegFilePdf } from "react-icons/fa6";
import { FaRegFileExcel } from "react-icons/fa";
import { FcClearFilters } from "react-icons/fc";
import { Tooltip } from "primereact/tooltip";

const Reports = () => {
  const userData = JSON.parse(localStorage.getItem("userData")) || "{}";
  const { isLoading, setIsLoading } = useLoader();
  const [jobsData, setJobsData] = useState([]);
  const [stageAmountData, setStageAmountData] = useState([]);
  const [projectionDispatchData, setProjectionDispatchData] = useState([]);
  const [isComponentReady, setIsComponentReady] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [customDateSelceted, setCustomDateSelceted] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState(0);
  const [selectedGroup, setSelectedGroup] = useState(0);
  const [productGroupsData, setProductGroupsData] = useState([]);
  const dt = useRef(null);

  const fetchJobsData = async () => {
    try {
      setIsLoading(true);
      let response;
      if (fromDate && toDate) {
        const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
        response = await axiosPrivate.post("reports/monthly_dispatch", {
          fromDate: new Date(fromDate).toLocaleDateString("en-GB", options),
          toDate: new Date(toDate).toLocaleDateString("en-GB", options),
          group_id: Number(selectedGroup) || 0,
        });
      } else {
        response = await axiosPrivate.post("reports/monthly_dispatch", {
          group_id: Number(selectedGroup) ,
        });
      }

      if (response.status === 200) {
        const Data = response?.data?.data || [];
        setProductGroupsData(response?.data?.prod_groups || []);
        const orderNumMap = new Map();

        const SoAmountMap = new Map();
        const orderQtyMap = new Map();

        // Creating Map to calculate the total amount of one SO
        // Data.forEach((line) => {
        //   if (SoAmountMap.has(line.OrderNum)) {
        //     SoAmountMap.set(
        //       line.OrderNum,
        //       SoAmountMap.get(line.OrderNum) +
        //         Number(line.IndianAmt.replaceAll(",", ""))
        //     );
        //   } else {
        //     SoAmountMap.set(
        //       line.OrderNum,
        //       Number(line.IndianAmt.replaceAll(",", ""))
        //     );
        //   }

        //   if (orderQtyMap.has(line.OrderNum)) {
        //     orderQtyMap.set(
        //       line.OrderNum,
        //       orderQtyMap.get(line.OrderNum) + Number(line.OrderQty)
        //     );
        //   } else {
        //     orderQtyMap.set(line.OrderNum, Number(line.OrderQty));
        //   }
        // });

        // Data.forEach((line) => {
        //   if (!orderNumMap.has(line.OrderNum)) {
        //     orderNumMap.set(line.OrderNum, {
        //       ...line,
        //       SOTotalAmount: SoAmountMap.get(line.OrderNum)
        //         ? SoAmountMap.get(line.OrderNum)
        //         : "-",
        //       TotalOrderQty: orderQtyMap.get(line.OrderNum)
        //         ? orderQtyMap.get(line.OrderNum)
        //         : "-",
        //     });
        //   }
        // });

        setJobsData([...Data]);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error?.message);
      } else if (error.response?.status === 404) {
        setJobsData([]);       
        setProductGroupsData(error?.response?.data?.prod_groups || []);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchStageAmountData = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get("reports/stage_wise_amount");

      if (response.status === 200) {
        setStageAmountData(response?.data?.data || []);
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error?.message);
      } else if (error.response?.status === 404) {
        setStageAmountData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const fetchProjectionDispatchData = async () => {
    try {
      setIsLoading(true);
      let response;
      if (fromDate && toDate) {
        const options = {
          day: "2-digit",
          month: "2-digit",
          year: "2-digit",
        };
        response = await axiosPrivate.post("reports/projection_dispatch", {
          fromDate: new Date(fromDate).toLocaleDateString("en-GB", options),
          toDate: new Date(toDate).toLocaleDateString("en-GB", options),
          group_id: Number(selectedGroup) || 0,
        });
      } else {
        response = await axiosPrivate.post("/reports/projection_dispatch", {
          group_id: Number(selectedGroup) || 0,
        });
      }

      if (response.status === 200) {
        setProjectionDispatchData(response?.data?.data || []);
        setProductGroupsData(response?.data?.prod_groups || []);      
      }
    } catch (error) {
      if (error.response?.status !== 404) {
        showErrorToast(error?.message);
      } else if (error.response?.status === 404) {
        setProductGroupsData(error?.response?.data?.prod_groups || []);
        setProjectionDispatchData([]);
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    switch (activeTabIndex) {
      case 0:
        fetchJobsData();
        break;
      case 1:
        fetchProjectionDispatchData();
        break;
      case 2:
        fetchStageAmountData();
        break;
      default:
        break;
    }
  }, [activeTabIndex , selectedGroup]);

  useEffect(() => {
    setIsComponentReady(true);
  }, []);


   const getActiveData = () => {
     if (activeTabIndex === 0) {
       return Number(selectedPlant) === 0
         ? jobsData
         : jobsData?.filter(
             (item) => Number(item.Plant) === Number(selectedPlant)
           );
     } else if (activeTabIndex === 2) {
       return stageAmountData;
     } else if (activeTabIndex === 1) {
       return Number(selectedPlant) === 0
         ? projectionDispatchData
         : projectionDispatchData?.filter(
             (item) => Number(item.Plant) === Number(selectedPlant)
           );
     }
     return [];
   };

  const exportPdf = () => {
    if (!isComponentReady || !dt.current) return;

    const doc = new jsPDF();
   

    const activeData = getActiveData();

    const generateHeader = (currentPage, pageSize) => {
      doc.setFontSize(12); // Increased font size for the heading
      doc.setTextColor(40);
      doc.setFont("helvetica", "normal");
      if (currentPage > 1) {
        doc.setPage(currentPage);
      }
      doc.text(
        activeTabIndex === 0
          ? "Tempsens - Dispatch Report"
          : activeTabIndex === 2
          ? "Tempsens - Stage-wise Amount Report"
          : "Tempsens - Monthly Projection Dispatch Report",
        pageSize.width / 2,
        17,
        { align: "center" }
      );
      doc.setFontSize(10);
      if ((fromDate && toDate) && (activeTabIndex === 0 || activeTabIndex === 1) ) {
        doc.text(
          `From: ${fromDate.toLocaleDateString(
            "en-GB"
          )} to ${toDate.toLocaleDateString("en-GB")}`,
          10,
          27
        );
      }

        if (
        Number(selectedPlant) !== 0 &&
          (activeTabIndex === 0 || activeTabIndex === 1)
        ) {           
          doc.text(
            `Plant: ${
              userData?.company_and_plants?.find(
          (plant) => Number(plant.plant_code) === Number(selectedPlant)
              )?.plant_name
            }`,
            10,
            22
          );
        }

      doc.text(
        `Total ${
          activeTabIndex === 0 || activeTabIndex === 1 ? "Jobs" : "Stages"
        }: ${activeData.length}`,
        pageSize.width - 10,
        22,
        {
          align: "right",
        }
      );

     
      doc.text(
        `Page ${currentPage}`,
        pageSize.width / 2,
        pageSize.height - 10,
        {
          align: "center",
        }
      );
       doc.setFontSize(12);
    };

    const columns =
      activeTabIndex === 0
        ? [
          { title: "Sr. No.", dataKey: "index" },
          { title: "Product Groups", dataKey: "ProdGroups" },
          { title: "Order No.", dataKey: "OrderNum" },
          { title: "Order Date", dataKey: "OrderDt" },
          { title: "Customer Name", dataKey: "CustomerName" },
          { title: "State", dataKey: "State" },
          { title: "Production Quantity", dataKey: "ProdQty" },
          { title: "Total Amount", dataKey: "IndianAmt" },
          { title: "Due Date", dataKey: "NeedDt" },
          { title: "Release Date", dataKey: "release_date" },
          ]
        : activeTabIndex === 2
        ? [
            { title: "Sr. No.", dataKey: "index" },
            { title: "Stage Name", dataKey: "stage_name" },
            { title: "Stage Load", dataKey: "jobs_count" },
            { title: "Total Amount", dataKey: "total_IndianAmt" },
          ]
        : [
            { title: "Sr. No.", dataKey: "index" },
            { title: "Product Groups", dataKey: "ProdGroups" },
            { title: "Order No.", dataKey: "OrderNum" },
            { title: "Order Date", dataKey: "OrderDt" },
            { title: "Customer Name", dataKey: "CustomerName" },
            { title: "State", dataKey: "State" },
            { title: "Production Quantity", dataKey: "ProdQty" },
            { title: "Total Amount", dataKey: "IndianAmt" },
            { title: "Due Date", dataKey: "NeedDt" },
            { title: "Promise Date", dataKey: "target_date" },
            { title: "Release Date", dataKey: "release_date" },
          ];

    const rows = activeData.map((row, index) => ({
      ...row,
      index: index + 1,
      IndianAmt: row.IndianAmt
        ? Number(row.IndianAmt).toLocaleString("en-IN")
        : "-",
      ProdQty: row.ProdQty ? row.ProdQty : "-",
      release_date: row.release_date ? new Date(row.release_date).toLocaleDateString("en-GB") : "-",
      NeedDt: row.NeedDt ? new Date(row.NeedDt).toLocaleDateString("en-GB") : "-",
      target_date: row.target_date ? new Date(row.target_date).toLocaleDateString("en-GB") : "-",
      OrderDt: row.OrderDt ? new Date(row.OrderDt).toLocaleDateString("en-GB") : "-",
      State: row.State ? row.State : "-",

    }));

    let finalY = 30; // Starting Y position for the table

    doc.autoTable({
      head: [columns.map((column) => column.title)],
      body: rows.map((row) => columns.map((column) => row[column.dataKey])),
      startY: finalY,
      theme: "grid",
      margin: { top: 30 }, // Ensures there's enough space for the header
      styles: {
        overflow: "linebreak", // Ensure text doesn't overflow cells
        fontSize: 6,
      },
      headStyles: {
        fillColor: [35, 16, 74],
        textColor: [255, 255, 255], // White text color for contrast
        fontStyle: "bold", // Bold text for the header
      },
      bodyStyles: {
        valign: "top",
      },
      rowPageBreak: "avoid", // Avoid splitting rows between pages
      willDrawCell: function (data) {
        if (data.row.section === "body") {
          const remainingSpace = doc.internal.pageSize.height - data.cursor.y;
          const rowHeight = data.cell.height;
          if (rowHeight > remainingSpace) {
            finalY = 30; // Reset Y position for the new page
            doc.addPage();
            generateHeader(
              doc.internal.getNumberOfPages(),
              doc.internal.pageSize
            );
          }
        }
      },
      didDrawPage: function (data) {
        generateHeader(data.pageNumber, doc.internal.pageSize);
      },
    });

    doc.save(
      activeTabIndex === 0
        ? "dispatch_report.pdf"
        : activeTabIndex === 2
        ? "stagewise_amount_report.pdf"
        : "projection_dispatch_report.pdf"
    );
  };


 

  const exportExcel = () => {
    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName =
      activeTabIndex === 0
        ? "dispatch_data"
        : activeTabIndex === 2
        ? "stagewise_amount_data"
          : "projection_dispatch_report";
    
    
    const activeData = getActiveData();
    const ws = XLSX.utils.json_to_sheet(activeData);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, {
      bookType: "xlsx",
      type: "array",
    });
    const data = new Blob([excelBuffer], { type: fileType });
    const url = window.URL.createObjectURL(data);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
  };

  const handleClearFilter = () => {
    setFromDate(null);
    setToDate(null);
    setSelectedGroup(0);
    setSelectedPlant(0);

    switch (activeTabIndex) {
      case 0:
        fetchJobsData();
        break;
      case 2:
        fetchStageAmountData();
        break;
      case 1:
        fetchProjectionDispatchData();
        break;
      default:
        break;
    }

    setCustomDateSelceted(false);
  };

  const renderHeader = () => {
    return (
      <div className='row d-flex justify-content-between align-items-center pl-2 pr-2 ' id = 'reports'>
        <div>
          <button
            className='btn btn-primary'
            onClick={exportPdf}
          >
            <FaRegFilePdf
              size={17}
              className='mr-1'
            />
            Export as PDF
          </button>
          <button
            className='btn btn-primary ml-2'
            onClick={exportExcel}
          >
            <FaRegFileExcel
              size={17}
              className='mr-1'
            />
            Export as Excel
          </button>
        </div>

        {activeTabIndex === 0 || activeTabIndex === 1 ? (
          <div>
            <div className='d-flex justify-content-between align-items-center row'>
              <div className='mb-2 d-flex justify-content-between align-items-center'>
                {Number(userData?.role_id) === 3 ? (
                  <div className='m-2'>
                    <label htmlFor='plant_id'>Plant:</label>

                    <div className='d-flex flex-column col-md dropdown text-left w-100 pl-0'>
                      <select
                        className='btn dropdown-toggle text-left'
                        style={{ background: "#fff" }}
                        onChange={(e) => {
                          if (Number(e.target.value) !== 2010) {
                            setSelectedGroup(0);
                            fetchJobsData();
                          }
                          setSelectedPlant(e.target.value);
                        }}
                        value={selectedPlant}
                      >
                        <option value='0'>All</option>
                        {userData?.company_and_plants?.map((plant) => (
                          <option
                            value={plant?.plant_code}
                            key={plant?.plant_id}
                          >
                            {plant?.plant_name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                ) : null}
                {
                  Number(selectedPlant) === 2010 || (Number(userData?.role_id) === 1 && Number(userData?.company_code) === 2000)
                  // || userData?.company_and_plants?.filter(
                  //   (plant) =>
                  //     Number(plant.plant_code) === 2010
                  // )?.length 
                  ? (
                      <div className='m-2'>
                        <label htmlFor='product_group'>Product Groups:</label>

                        <div className=' d-flex flex-column col-md dropdown text-left w-100 pl-0'>
                          <select
                            className='btn dropdown-toggle text-left'
                            style={{ background: "#fff" }}
                            onChange={(e) => setSelectedGroup(e.target.value)}
                            value={selectedGroup}
                          >
                            <option value='0'>All</option>
                            {productGroupsData?.map((group) => (
                              <option
                                value={group?.id}
                                key={group?.id}
                              >
                                {group?.name}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ): null
                }
              </div>

              {/* Calendar inputs for custom range */}
              <div className='d-flex flex-column col-md'>
                <span>From: </span>
                <Calendar
                  value={fromDate}
                  onChange={(e) => setFromDate(e.value)}
                  showIcon
                  readOnlyInput
                  dateFormat='dd-mm-yy'
                  placeholder='dd-mm-yyyy'
                />
                {!fromDate && customDateSelceted ? (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    Please select From Date!
                  </div>
                ) : null}
              </div>
              <div className='d-flex flex-column  col-md'>
                <span className='ml-2'>To: </span>
                <Calendar
                  value={toDate}
                  onChange={(e) => setToDate(e.value)}
                  readOnlyInput
                  showIcon
                  dateFormat='dd-mm-yy'
                  placeholder='dd-mm-yyyy'
                />
                {!toDate && customDateSelceted && (
                  <div
                    style={{
                      color: "red",
                      fontSize: "12px",
                    }}
                  >
                    Please select to Date!
                  </div>
                )}
              </div>
              <div className='col-auto'>
                <button
                  className='btn btn-primary ml-2 mt-3'
                  onClick={() => {
                    setCustomDateSelceted(true);
                    if (fromDate && toDate) {
                      switch (activeTabIndex) {
                        case 0:
                          fetchJobsData();
                          break;
                        case 2:
                          fetchStageAmountData();
                          break;
                        case 1:
                          fetchProjectionDispatchData();
                          break;
                        default:
                          break;
                      }
                    }
                  }}
                  style={{ cursor: "pointer" }}
                >
                  Apply
                </button>
                <FcClearFilters
                  className=' ml-2 mt-3'
                  size={25}
                  onClick={handleClearFilter}
                  style={{ cursor: "pointer" }}
                />
              </div>
            </div>
          </div>
        ) : null}
      </div>
    );
  };

  const header = renderHeader();

  const indexBodyTemplate = (rowData) => {
    return <span>{rowData.index}</span>;
  };

  const renderProductCategory = (rowData) => {
    const text = rowData.ProdGroups || "-";
    const displayText = text.length > 30 ? text.substring(0, 30) + "..." : text;
    return (
      <>
        <span id={`prodGroupTooltip_${rowData.OrderNum}`}>{displayText}</span>
        {text.length > 30 && (
          <Tooltip
            target={`#prodGroupTooltip_${rowData.OrderNum}`}
            content={text}
          />
        )}
      </>
    );
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <div className='content'>
            <TabView
              activeIndex={activeTabIndex}
              onTabChange={(e) => setActiveTabIndex(e.index)}
            >
              <TabPanel header='Monthly Actual Dispatch'>
                <div
                  className='table-responsive '
                  style={{ minHeight: `calc(100vh - 106px)` }}
                >
                  <DataTable
                    header={header}
                    value={
                      Number(selectedPlant) === 0
                        ? jobsData
                        : jobsData?.filter(
                            (item) =>
                              Number(item.Plant) === Number(selectedPlant)
                          )
                    }
                    style={{ overflow: "scroll" }}
                    editMode='row'
                    paginator
                    rowsPerPageOptions={[20, 40, 60, 80]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={20}
                    size='small'
                    dataKey='id'
                    id='jobsTable'
                    ref={dt}
                    showGridlines
                    tableStyle={{ minWidth: "1400px" }}
                    emptyMessage='No records found'
                  >
                    <Column
                      field='ProdGroups'
                      header='Product Groups'
                      sortable={true}
                      body={renderProductCategory}
                    />
                    <Column
                      field='OrderNum'
                      header='Sales Order No.'
                      sortable={true}
                      align={"right"}
                    />
                    <Column
                      field='OrderDt'
                      header='Order Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.OrderDt
                          ? new Date(rowData?.OrderDt).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />
                    <Column
                      field='CustomerName'
                      header='Customer Name'
                      sortable={true}
                    />
                    <Column
                      field='State'
                      header='State'
                      sortable={true}
                    />
                    <Column
                      field='ProdQty'
                      header='Production Quantity'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.ProdQty ? rowData?.ProdQty : "-"
                      }
                    />

                    <Column
                      field='IndianAmt'
                      header='Total Amount'
                      body={(rowData) =>
                        rowData?.IndianAmt
                          ? Number(rowData?.IndianAmt).toLocaleString("en-IN")
                          : "-"
                      }
                      sortable={true}
                      align={"right"}
                    />
                    <Column
                      field='NeedDt'
                      header='Due Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.NeedDt
                          ? new Date(rowData?.NeedDt).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />
                    <Column
                      field='release_date'
                      header='Release Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.release_date
                          ? new Date(rowData?.release_date).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />
                  </DataTable>
                </div>
              </TabPanel>

              <TabPanel header='Monthly Projection Dispatch'>
                <div
                  className='table-responsive'
                  style={{ minHeight: `calc(100vh - 106px)` }}
                >
                  <DataTable
                    header={header}
                    value={
                      Number(selectedPlant) === 0
                        ? projectionDispatchData
                        : projectionDispatchData?.filter(
                            (item) =>
                              Number(item.Plant) === Number(selectedPlant)
                          )
                    }
                    style={{ overflow: "scroll" }}
                    editMode='row'
                    paginator
                    rowsPerPageOptions={[20, 40, 60, 80]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={20}
                    size='small'
                    dataKey='id'
                    id='projectionTable'
                    ref={dt}
                    showGridlines
                    emptyMessage='No records found'
                  >
                    <Column
                      field='ProdGroups'
                      header='Product Groups'
                      sortable={true}
                      body={renderProductCategory}
                    />
                    <Column
                      field='OrderNum'
                      header='Sales Order'
                      sortable={true}
                      align={"right"}
                    />
                    <Column
                      field='OrderDt'
                      header='Order Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.OrderDt
                          ? new Date(rowData?.OrderDt).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />

                    <Column
                      field='CustomerName'
                      header='Customer Name'
                      sortable={true}
                    />
                    <Column
                      field='State'
                      header='State'
                      sortable={true}
                    />
                    <Column
                      field='ProdQty'
                      header='Production Quantity'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.ProdQty ? rowData?.ProdQty : "-"
                      }
                    />

                    <Column
                      field='IndianAmt'
                      header='Total Amount'
                      body={(rowData) =>
                        rowData?.IndianAmt
                          ? Number(rowData?.IndianAmt).toLocaleString("en-IN")
                          : "-"
                      }
                      sortable={true}
                      align={"right"}
                    />
                    <Column
                      field='NeedDt'
                      header='Due Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.NeedDt
                          ? new Date(rowData?.NeedDt).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />
                    <Column
                      field='target_date'
                      header='Promise Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.target_date
                          ? new Date(rowData?.target_date).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />
                    <Column
                      field='release_date'
                      header='Release Date'
                      sortable={true}
                      body={(rowData) =>
                        rowData?.release_date
                          ? new Date(rowData?.release_date).toLocaleDateString(
                              "en-GB"
                            )
                          : "-"
                      }
                    />
                  </DataTable>
                </div>
              </TabPanel>
              <TabPanel header='Stage-wise Amount'>
                <div
                  className='table-responsive'
                  style={{ minHeight: `calc(100vh - 106px)` }}
                >
                  <DataTable
                    header={header}
                    value={stageAmountData}
                    style={{ overflow: "scroll" }}
                    editMode='row'
                    paginator
                    rowsPerPageOptions={[20, 40, 60, 80]}
                    paginatorTemplate='RowsPerPageDropdown FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink'
                    currentPageReportTemplate='{first} to {last} of {totalRecords}'
                    rows={20}
                    size='small'
                    dataKey='id'
                    id='stagesTable'
                    ref={dt}
                    showGridlines
                    emptyMessage='No records found'
                  >
                    <Column
                      field='stage_name'
                      header='Stage Name'
                      sortable={true}
                    />
                    <Column
                      field='jobs_count'
                      header='Stage Load(No. of Jobs)'
                      sortable={true}
                      align={"right"}
                    />
                    <Column
                      field='total_IndianAmt'
                      header='Total Amount'
                      body={(rowData) =>
                        rowData?.total_IndianAmt
                          ? Math.round(rowData?.total_IndianAmt).toLocaleString(
                              "en-IN"
                            )
                          : "-"
                      }
                      sortable={true}
                      align={"right"}
                    />
                  </DataTable>
                </div>
              </TabPanel>
            </TabView>
          </div>
        </>
      )}
    </>
  );
};

export default Reports;
