import React, { useEffect, useState } from "react";
import axiosPrivate from "../../hooks/axiosPrivate";
import { showErrorToast } from "../../utils/Toaster";
import Loader from "../../context/Loader/Loader";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { FilterMatchMode } from "primereact/api";
import { useLoader } from "../../context/Loader/LoaderProvider";
import { useSearchParams } from "react-router-dom";
import { FaAngleDoubleLeft, FaAngleLeft, FaAngleRight, FaAngleDoubleRight } from "react-icons/fa";

const AuditLogs = () => {
  const [logsData, setLogsData] = useState([]);
  const { isLoading, setIsLoading } = useLoader();
  let [searchParams, setSearchParams] = useSearchParams();



  const [pageNo, setPageNo] = useState(() => Number(searchParams.get("page")) || 1);
  const [offset, setOffset] = useState(() => Number(searchParams.get("offset")) || 20);
  const [searchText, setSearchText] = useState(() => searchParams.get("searchText") || "");
  const [totalRecords, setTotalRecords] = useState(0);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const params = {
        page: pageNo,
        offset, 
        searchText: searchText || "",
      };
      setSearchParams(params);

      const response = await axiosPrivate.get("logs", { params });

      if (response.status === 200) {
        const modifiedLogsData = response.data?.data?.logs.map((log, index) => ({
          ...log,
          index: (pageNo - 1) * offset + index + 1,
        }));

        setLogsData(modifiedLogsData);
        setTotalRecords(response.data?.data?.total?.[0]?.total || response.data?.data?.total || 0);
      }
    } catch (error) {
      if (error?.response?.status === 404) {
        setLogsData([]);
        setTotalRecords(0);
      } else {
        showErrorToast("Error fetching audit logs data");
      }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo, offset]);

  useEffect(() => {
    const debounceFetch = setTimeout(() => {
      fetchData();
    }, 500);

    return () => clearTimeout(debounceFetch);
  }, [searchText]);



  const handleRowsChange = (e) => {
    setOffset(Number(e.target.value));
    setPageNo(1); // Reset to the first page when changing the number of rows per page
  };

  const onFirstPage = () => setPageNo(1);
  const onPreviousPage = () => setPageNo((prev) => Math.max(prev - 1, 1));
  const onNextPage = () => setPageNo((prev) => Math.min(prev + 1, Math.ceil(totalRecords / offset)));
  const onLastPage = () => setPageNo(Math.ceil(totalRecords / offset));

  const header = (
    <div className="d-flex justify-content-between align-items-center">
      <h4>Audit Logs</h4>
      <InputText
        type="search"
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        placeholder="Global Search"
      />
    </div>
  );

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className='content'>
          <div className='card'>
            <div className='card-body p-0'>
              <div className='table-responsive' id="logs">
                <DataTable
                  value={logsData}
                  dataKey='id'
                  header={header}
                  paginator={false}
                    tableStyle={{ minWidth: "1400px" }}
                    emptyMessage="No logs found."
                >
                  <Column
                    field='index'
                    header='Sr. No.'
                    sortable
                  />
                  <Column
                    field='user_emp_code'
                    header='Employee Code'
                    sortable
                  />
                  <Column
                    field='user_name'
                    header='User Name'
                    sortable
                  />
                  <Column
                    field='role_name'
                    header='Role'
                    sortable
                  />
                  <Column
                    field='created_at'
                    header='Created At'
                    sortable
                  />
                  <Column
                    field='user_email'
                    header='User Email'
                    sortable
                  />
                  <Column
                    field='user_mobile'
                    header='Contact No.'
                    sortable
                  />
                  <Column
                    field='action'
                    header='Activity'
                    sortable
                    headerStyle={{ width: "35rem" }}
                  />
                </DataTable>
              </div>
            </div>
          </div>
          <div
            style={{
              margin: "10px 0px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <button
              onClick={onFirstPage}
              disabled={pageNo === 1}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              <FaAngleDoubleLeft size={20} />
            </button>
            <button
              onClick={onPreviousPage}
              disabled={pageNo === 1}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              <FaAngleLeft size={20} />
            </button>
            <span style={{ margin: "0 10px" }}>
              Showing Page {pageNo} of {Math.ceil(totalRecords / offset)} from
              total {totalRecords} entries
            </span>
            <button
              onClick={onNextPage}
              disabled={pageNo >= Math.ceil(totalRecords / offset)}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              <FaAngleRight size={20} />
            </button>
            <button
              onClick={onLastPage}
              disabled={pageNo >= Math.ceil(totalRecords / offset)}
              style={{ border: "none", background: "none", cursor: "pointer" }}
            >
              <FaAngleDoubleRight size={20} />
            </button>
            <select
              value={offset}
              onChange={handleRowsChange}
              style={{ marginLeft: "10px" }}
            >
              <option value={20}>20</option>
              <option value={40}>40</option>
              <option value={60}>60</option>
              <option value={80}>80</option>
            </select>
          </div>
        </div>
      )}
    </>
  );
};

export default AuditLogs;
