import { Fragment } from "react";
import SideBar from './SideBar';
import Header from './Header';
import Footer from './Footer'
import { Outlet } from "react-router-dom";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Main = (props) => {

  const userData = JSON.parse(localStorage.getItem('userData')) || {};

  return (
    <Fragment>
      <div className='font-montserrat'>
        <div id='main_content'>
         
            {Number(userData?.role_id) !== 2 &&
            Number(userData?.role_id) !== 15 ? (
              <div>
                <SideBar />
              </div>
            ) : null}

            <div
              className='page'
              style={{
                width:
                  Number(userData?.role_id) === 2 ||
                  Number(userData?.role_id) === 15
                    ? "100%"
                    : "calc(100% - 80px)",
                left:
                  Number(userData?.role_id) === 2 ||
                  Number(userData?.role_id) === 15
                    ? "0"
                    : "80px",
              }}
            >
              <Header />
              <Outlet />
              <Footer />
            
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default Main;
